<template>
  <!-- 培训记录 -->
  <div class="trainingRecord">
    <van-nav-bar
      :title="$t('examRecord.ErTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :fixed="true"
    />
    <div class="fixedH" style="height: 1.22667rem;"></div>
    <van-search
      v-model="value"
      shape="round"
      :placeholder="$t('examRecord.ErTxt2')"
      @search="onSearch"
      style="margin-bottom:10px;"
    />
    <!-- 审核中 -->
    <div class="reviewed">
      <van-list
        v-model="loadingAlready"
        :finished="finishedAlready"
        :finished-text="$t('examRecord.ErTxt3')"
        @load="onLoad"
      >
        <van-cell v-for="item in alreadyList" :key="item" @click="reviewedInfo(item)">
          <div class="up" style="padding-right: 1rem;">
            <p>{{ item.papername }}</p>
            <p>{{$t('examRecord.ErTxt4')}}{{ item.coursetype }}</p>
            <p>{{$t('examRecord.ErTxt5')}}{{ item.totalscore }}{{$t('toEvaluated.evaluateTxt8')}}</p>
            <span class="score">{{ item.score }}{{$t('toEvaluated.evaluateTxt8')}}</span>
          </div>
          <div class="down">
            <span>{{$t('examRecord.ErTxt6')}}{{ item.et }}</span>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import { getHisPostPaperList } from '@api/wxpx.js'
import { searchFunc } from '@/utils'
import { Toast } from 'vant'
export default {
  name: 'trainRecords',
  data() {
    return {
      userInfo,
      value: '',
      active: 0,
      isLoadingAwait: false,
      awaitList: [],
      loadingAwait: false,
      finishedAwait: false,
      isLoadingAlready: false,
      alreadyList: [],
      loadingAlready: false,
      finishedAlready: false,
      oldlist: [],
      form: {
        papername: ''
      }
    }
  },
  methods: {
    // 回退
    onClickLeft() {
      this.$router.push('/home')
    },
    onClickRight() {},
    onLoad() {
      getHisPostPaperList({
        empid: this.userInfo.empid
      }).then(res => {
        this.alreadyList = res.data
        this.oldlist = this.alreadyList
        // 加载状态结束
        this.loadingAlready = false
        this.finishedAlready = true
      })
    },
    reviewedInfo(itm) {
      this.$router.push({
        path: '/checkOutAnswer',
        query: {
          mautoid: itm.mautoid,
          paperid: itm.paperid,
          teststyle: itm.teststyle,
          returnPath: 'examRecord'
        }
      })
    },
    // 搜索
    onSearch() {
      var arr = searchFunc(this.value, this.form, this.oldlist)
      if (arr.length > 0) {
        this.alreadyList = arr
        this.value = ''
      } else {
        Toast(this.$t('examRecord.ErTxt7'))
      }
    }
  }
}
</script>

<style lang="less" scoped>
.trainingRecord {
  height: 100vh;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .reviewed {
    /deep/.van-list {
      .van-cell {
        border-radius: 8px 0px 0px 8px;
        border-left: 8px solid transparent;
        margin-bottom: 28px;
        padding-bottom: 0;
        .van-cell__value {
          .up {
            position: relative;
            font-family: Source Han Sans CN;
            border-bottom: 1px solid #ccc;
            font-size: 28px;
            color: #999;
            padding-bottom: 22px;
            .score {
              position: absolute;
              right: 0;
              top: 38px;
              font-size: 40px;
              font-family: DIN;
              font-weight: bold;
            }
            p {
              margin: 0;
            }
            p:first-child {
              font-size: 32px;
              color: #333;
              line-height: 54px;
            }
            p:nth-child(2) {
              line-height: 48px;
            }
            p:last-child {
              line-height: 48px;
            }
          }
          .down {
            display: flex;
            align-items: center;
            height: 78px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>